.card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to left, #002d7a, #7298da);
  padding: 1rem;
  margin: 0 auto;
  max-width: 70%;
  border-radius: 15px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 1rem;
  color: white;
}

.card-button {
  background-color: #3fa9f5;
  transition: background-color 0.3s ease-in-out;
  width: 30%;
}

.card-button:hover {
  background-color: #1952cc;
}

.card-button:active {
  background-color: #7298da;
}
