* {
  font-family: "Poppins", sans-serif;
}

.hero-container {
  background-color: #f9fbff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 60% 15%;
  /* background-color: red; */
}

.hero-container .hero-content {
  text-align: center;
  /* margin-top: -10%; */

  margin-left: 3%;
  /* background-color: red; */
}

.hero-container .hero-content h1 {
  flex-direction: column;
  font-size: 150%;
  margin: auto;
  /* margin-bottom: px; */
}

.hero-container .hero-content p {
  font-size: 150%;
  margin-bottom: 20px;
}

.hero-container .hero-image {
  margin-top: 20px;
  /* max-width: 100%; */
  width: 90%;
  height: 80%;
  text-align: center;
}

.hero-container .hero-image img {
  /* max-width: 100%; */
  width: 100%;
  background-size: cover;
  height: 100%;
}
.subtitle-hero-hero {
  /* font-family: "Poppins", sans-serif; */
}

.subtitle-hero span {
  font-size: 150%;
}

.p-sucess-color {
  color: rgb(0, 174, 255);
}

.bold-p-sucess-color {
  color: rgb(0, 174, 255);
  font-weight: 1000;
  font-size: 3em;
}

/* Media queries para ajustar o layout em diferentes tamanhos de tela */

@media (min-width: 768px) {
  .hero-container {
    flex-direction: row;
    padding: 0 5%;
  }

  .hero-container .hero-content {
    margin-right: 50px;
    text-align: left;
  }

  .hero-container .hero-content h1 {
    font-size: 50px;
  }

  .hero-container .hero-content p {
    font-size: 28px;
  }

  .hero-container .hero-image {
    margin-top: 0;
    margin-left: 50px;
    text-align: right;
  }
}

@media (min-width: 1200px) {
  .hero-container {
    padding: 0 10%;
  }
}
