.container-management-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  word-wrap: break-word;
  min-width: 50%;
  /* height: 244px; */
}

.card {
  width: 244px;
  height: 244px;
  /* border-radius: 12px; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: auto;
}

.card:hover {
  background-color: #64aef8;
  /* color: white; */
}

.card-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  /* height: 100x; */
  /* margin-top: 20px; */
}

.footer-cards {
  margin-top: 3rem;
  text-align: center;
  width: 100%;
  color: blue;
}

.message-business-cards {
  color: rgb(102, 102, 102);
  min-width: 100%;
  font-size: 6.8vh;
  /* font-size: 4rem; */
  font-weight: 1000;
}

.card-title-title {
  text-align: center;
  color: black;
  transition: color 0.3s;
}

.card:hover .card-title-title {
  color: white;
}
.ant-card-meta-title:hover {
  color: white;
}

.wrap-text .ant-card-meta-title {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* Media queries para ajustar o layout em diferentes tamanhos de tela */

@media (max-width: 768px) {
  .cards {
    justify-content: space-between;
  }
  .message-business-cards {
    font-size: 2rem;
  }
}

@media (min-width: 1200px) {
  .cards {
    justify-content: flex-start;
  }
}
