.container-cards-dash-ilustrator {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  margin-top: 2%;
  margin-bottom: 3%;
  /* background-color: rgb(247, 249, 255); */
  background-color: #a4b8ff;
  padding-top: 3%;
  padding-bottom: 3%;
}

.full-card {
  /* width: 30%; */
  /* object-fit: cover; */
  background-color: red;
  color: rgb(189, 189, 189);
}

.full-card-image {
  /* width: 100%;
    height: 100%; */
  object-fit: cover;
}

.small-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.small-card {
  /* height: 3px; */
  width: 280px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);   */
}

.small-card-logo {
  /* width: 40%; */
  /* height: 40px; */
  /* margin-right: 10px; */
}

.small-card-title {
  font-size: 16px;
  font-weight: bold;
}

.small-card li {
  margin-bottom: 5px;
}

.title-cond {
  font-weight: 1000;
  font-size: 33px;
  font-family: "Poppins", sans-serif;
}
