body {
  margin: 0;
  padding: 0;
}

.header-bg1 {
  width: 100%;
  height: 100%;
  /* background: #000000; */
}

.header-bg2 {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #0a1930;
}

.home-titiles h1 {
  color: #f48213;
  font-size: 24px;
  font-weight: bold;
  align-items: center;
  text-align: center;
}

.home-titiles h2 {
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  text-align: center;
}

.home-titiles hr {
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
}
