footer {
  position: relative;
  height: 250px;
  background-color: #7594fb;
  opacity: 0.9;
}

.footer-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/home.png");
  background-size: cover;
  /* opacity: 0.;  */
  z-index: -1;
}

.footer-content {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 20px;
}

.logo-footer {
  position: absolute;
  top: 20px;
  left: 50px;
}

.logo-footer img {
  width: 200px;
}

.footer-column {
  width: 25%;
  display: inline-block;
  vertical-align: top;
  text-align: left;
  margin-top: 5%;
}

.footer-column h2 {
  color: white;
  font-size: 18px;
  margin-bottom: 10px;
  margin-left: 30px;
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
  color: white;
  font-size: 12px;
}

.footer-column ul li {
  margin-bottom: 10px;
  margin-left: 30px;
}

.footer-black {
  text-align: center;
  background-color: black;
  color: white;
  padding: 4px;
}

.footer-black span {
  display: inline-block;
  vertical-align: middle;
}

.footer-black img {
  display: inline-block;
  vertical-align: middle;
}

.footer-black img {
  width: 100px;
}

@media (max-width: 767px) {
  .footer-column {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .footer-column h2 {
    text-align: center;
  }

  .footer-column ul li {
    text-align: center;
  }

  .logo-footer {
    position: static;
    margin-bottom: 20px;
  }

  .footer-black {
    position: static;
    text-align: center;
    margin-top: 20px;
  }
}
