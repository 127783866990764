.testimonial-carousel-container {
  /* display: flex; */
  /* justify-content: center; */
  margin-left: 20px; /* Adicione a margem desejada à esquerda */
  padding: 0 20%;
  /* width: 200px; */
  /* height: 200px; */
  overflow: hidden;
}
.testimonial-carousel {
}
.carousel-image {
  width: 350px;
  height: 350px;
  /* padding: 0 20%; */
  background-size: cover;
  margin-left: 20%;
  /* border-radius: 50%; */
  /* Adicione qualquer estilo adicional para a imagem bonita */
  /* background-color: red; */
}

.carousel-title {
  font-size: 25px;
  /* font-family: "Helvetica Neue", sans-serif;  */
  margin-bottom: 20px;
  text-align: center;
}

.testimonial-comment {
  font-size: 24px;
  /* font-weight: bold; */
  /* margin-top: 1rem; */
  /* font-family: "Helvetica", sans-serif; */
}
