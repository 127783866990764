.hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5%;
}

.phone-image {
  max-width: 100%;
  height: auto;
}

.description {
  font-size: 24px;
}

.description h1 {
  font-size: 26px;
  margin-bottom: 1rem;
}

.description ul {
  margin-bottom: 1rem;
  padding-left: 1rem;
}

ul {
  list-style-type: circle;
}

.description li {
  padding: 0;
  margin: 0;
}
.cond-list {
  list-style: none;
  font-size: 20px;
}

.digite {
  font-size: 20px;
  /* margin: 10px; */
  font-weight: 600;
  color: rgb(78, 78, 78);
}

.card-blur {
  margin-right: auto;
  margin-left: auto;
  /* width: 250px; */
  box-shadow: 0 1px 30px rgba(129, 124, 124, 0.2);
  /* height: 300px; */
  /* border-radius: 5px; */
  /* backdrop-filter: blur(14px); */
  background-color: rgba(255, 255, 255, 0.2);
  padding: 1px;
  /* text-align: center; */
}

@media (min-width: 768px) {
  .hero-section {
    padding: 0 10%;
  }

  .phone-image {
    max-width: 300px;
  }

  .description {
    font-size: 28px;
  }

  .description h1 {
    font-size: 30px;
  }
}

@media (min-width: 1200px) {
  .hero-section {
    padding: 0 15%;
  }

  .phone-image {
    max-width: 400px;
  }

  .description {
    font-size: 32px;
  }

  .description h1 {
    font-size: 34px;
  }
}
