.fixar {
  position: fixed;
  background: transparent;
  top: 0;
  width: 100%;
  z-index: 30;
  /* border: 1px solid #dfe5e7; */
}

.fixar.active {
  background: linear-gradient(
    90deg,
    rgb(254, 254, 254) 0%,
    rgb(254, 254, 254) 100%
  );
  border: 1px solid #dfe5e7;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7rem;
  padding: 0 12.5%;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.logo-text {
  font-size: 40px;
  font-weight: 300;
  color: white;
}

.logo-text img {
  padding-top: 10px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.logo-text span {
  font-weight: 600;
  color: #f48124;
}

.nav-links {
  display: flex;
  color: black;
}

menu {
  /* Distancia do menus */
  margin: 20%;
}

.nav-links li:hover {
  border-bottom: 3px solid #4737d1;
}

.nav-links li.active {
  border-bottom: 3px solid #4737d1;
}

.nav-links li {
  display: flex;
  align-items: center;
  margin-left: 15px;
  list-style: none;
  font-weight: bold;
}
.nav-links a {
  font-size: 20px;
  /* font-weight: 450; */
  font-weight: 900;
  color: black;
  text-decoration: none;
  margin-right: 2rem;
  transition: all 0.3s;
}

.nav-links a:hover,
#nav-links-mobile a:hover {
  color: #4737d1;
}

.navbar .menu-icons {
  background: transparent;
  border: none;
  border-radius: 3px;
  display: none;
}

/* Media Queries */
@media screen and (max-width: 769px) {
  /* hide nav button on navbar */
  .nav-btn {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  /* show nav button on navbar menu */
  .nav-btn {
    display: block;
  }

  .nav-links {
    display: none;
    /* border: 2px solid #fff; */
    border: black;
    z-index: 111;
  }
  /* font-family: "Poppins", sans-serif;
  color: red; */

  .navbar .menu-icons {
    display: block;
    background: black;
  }

  .navbar #nav-links-mobile,
  .navbar #nav-links-mobile-hide {
    display: block;
    position: absolute;
    list-style: none;
    background: white;
    left: 0;
    height: 100%;
    top: 100%;
    width: 80%;
    transition: all 0.3s;
    /* border-top: 1px solid #fff; */
    border-top: 1px solid black;
    padding-bottom: 5px;
  }

  .navbar #nav-links-mobile {
    animation: slide-in 0.5s ease-in-out;
  }

  .navbar #nav-links-mobile-hide {
    left: -100%;
    animation: slide-out 0.5s ease-in-out;
  }

  @keyframes slide-out {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-100%);
    }
  }

  @keyframes slide-in {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .navbar #nav-links-mobile li,
  .navbar #nav-links-mobile-hide li {
    text-align: center;
    padding: 10px 0;
    /* border-bottom: 1px solid #fff; */
    border-bottom: 1px solid black;
    margin: 0 30px;
  }

  .navbar #nav-links-mobile li:last-child {
    border-bottom: none;
  }

  .navbar #nav-links-mobile li a {
    /* color: #fff; */
    color: black;
    text-decoration: none;
    font-size: 18px;
  }

  .navbar #nav-links-mobile a:hover {
    color: #f48124;
  }
}

.entrar-button {
  display: inline-block;
  padding: 1px 5px;
  border: 1px solid #1890ff;
  border-radius: 4px;
  text-decoration: none;
  color: #1890ff;
  transition: all 0.3s ease;
}

.entrar-button a {
  color: #1890ff;
}

.entrar-button:hover {
  background-color: #1890ff;
  color: #fff;
}
